import * as React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Banner from 'components/Banner';
import Loader from 'components/Loader';

const IndexPage: React.FunctionComponent = () => {
  return (
    <Layout displayFooter={false}>
      <SEO title="StreamEYE" description="Welcome to StreamEYE powered by StreamLINE-Solutions"/>
      <Loader />
      <Banner />
    </Layout>
  );
};

export default IndexPage;
