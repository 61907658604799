/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';


import './_styles.scss';
import Header from './Header';

interface ILayout {
  children: React.ReactNode;
  displayFooter?: boolean;
}

const Layout: React.FunctionComponent<ILayout> = ({ children, displayFooter = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="layout" id="main">
      <Header />
      <main style={{ height: '100%', width: '100%' }}>{children}</main>
    </div>
  );
};

export default Layout;
