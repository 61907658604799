import React, { useEffect, useState } from "react";
import { Scrollchor } from 'react-scrollchor';

import './_styles.scss';
import LanguageSelector from 'components/LanguageSelector';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FormattedMessage } from "react-intl";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo_vertical.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const sections = document.querySelectorAll('section');
      const menuItems = document.querySelectorAll('.navbar-nav a');
      document.addEventListener("scroll", () => {
        let current = '';
        setSmall(window.pageYOffset > 200);

        sections.forEach(section => {
          const sectionTop = section.offsetTop;
          if (scrollY > sectionTop - window.innerHeight / 2) {
            current = section.getAttribute('id') || '';
          }
        })

        menuItems.forEach(a => {
          a.classList.remove('active');
          if (a.classList.contains(current)) {
            a.classList.add('active');
          }
        })
      }, false);
    }
  }, []);

  return (
    <div className="layoutheader">
      <div className="layoutheader__content">
        <div className={`navbar-top-default navbar-expand-lg navbar-simple nav-line ${small ? "scrolled" : ""}`}>
          <div className="header-container">
            <a href="#home" title="Logo" className="logo scroll">
              {/* <GatsbyImage
                className="header-logo"
                image={data.file.childImageSharp.gatsbyImageData}
                alt="streamline-logo-vertical" /> */}
            </a>
            <div className="collapse navbar-collapse" id="megaone">
              {/* <div className="navbar-nav ml-auto">
                <Scrollchor to="#home" className="nav-link home"><FormattedMessage id="menu.home" defaultMessage="Home" /></Scrollchor>
                <Scrollchor to="#about" className="nav-link about"><FormattedMessage id="menu.aboutUs" defaultMessage="About Us" /></Scrollchor>
                <Scrollchor to="#portfolio" className="nav-link portfolio"><FormattedMessage id="menu.services" defaultMessage="Services" /></Scrollchor>
                <Scrollchor to="#products" className="nav-link products"><FormattedMessage id="menu.products" defaultMessage="Products" /></Scrollchor>
                <Scrollchor to="#contact" className="nav-link contact"><FormattedMessage id="menu.contact" defaultMessage="Contact" /></Scrollchor>
              </div> */}

            </div>
            <div className="language-menu">
              <LanguageSelector className="layoutfooter__select" />
            </div>
          </div>
        </div>
        <div className="navigation-toggle">
          {/* <ul className="slider-social toggle-btn my-0">
            <li>
              <a href="javascript:void(0);" className="sidemenu_btn" id="sidemenu_toggle">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>)
};

export default Header;
