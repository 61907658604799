/*
  https://www.gatsbyjs.com/docs/reference/release-notes/image-migration-guide/
  https://www.gatsbyjs.com/plugins/gatsby-plugin-image/
  https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/
*/
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo_vertical.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const colorWhite = {
    color: '#fff',
  };

  return (
    <>
      <div id="overlay"></div>
      <section id="home" style={{ height: '100%', width: '100%' }}>
        <div className="banner-text-wrapper alt-font">
          <h1 className="banner-text">
            <span className="stream-eye">StreamEYE</span> <span className="cerevision">Cerevision</span> <br />
            <div className="save-up-wrapper-mobile">
              <br />
              <h3 className="banner-text-small-save-up" style={colorWhite}><FormattedMessage id="streameye.line4" defaultMessage="Save up to 80% on your yeast purchases!" /></h3>
              <a href="mailto:info@streamline-solutions.ch" className="btn btn--white btn--animated"><FormattedMessage id="streameye.contactUs" defaultMessage="Contact us" /></a>
            </div>
          </h1>
          <div className="banner-text-small">
            <h6><FormattedMessage id="streameye.line1" defaultMessage="The adventure begins: exclusive information will soon be unveiled." /></h6>
            <h6><FormattedMessage id="streameye.line2" defaultMessage="Our cutting-edge technology, in its final development phase, is about to disrupt the industry." /></h6>
            <h6><FormattedMessage id="streameye.line3" defaultMessage="Be among the first visionaries to join the brewing revolution!" /></h6>
            <div className="save-up-wrapper-wide">
              <h3 className="banner-text-small-save-up" style={colorWhite}><FormattedMessage id="streameye.line4" defaultMessage="Save up to 80% on your yeast purchases!" /></h3>
              <a href="mailto:info@streamline-solutions.ch" className="btn btn--white btn--animated"><FormattedMessage id="streameye.contactUs" defaultMessage="Contact us" /></a>
            </div>
          </div>
        </div>
        <div className="banner"></div>

        <div className="powered-by-logo">
          <span className="powered-by">Powered by</span>
          <a href="https://streamline-solutions.ch/" title="Logo" className="logo scroll">
            <GatsbyImage
              className="header-logo"
              image={data.file.childImageSharp.gatsbyImageData}
              alt="streamline-logo-vertical" />
          </a>
        </div>
        {/* <GatsbyImage
          className="banner-logo"
          image={data.file.childImageSharp.gatsbyImageData}
          alt="streamline-logo" /> */}
      </section >
    </>
  )
}

export default Banner


