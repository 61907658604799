import * as React from 'react';
import cn from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { changeLanguage } from 'store/state/language/operations';
import { selectLocale } from 'store/state/language/selectors';

import './_styles.scss';

const translations = defineMessages({
  en: { id: 'language.selector.option.english', defaultMessage: 'English' },
  fr: { id: 'language.selector.option.french', defaultMessage: 'French' },
  de: { id: 'language.selector.option.german', defaultMessage: 'German' },
  it: { id: 'language.selector.option.italian', defaultMessage: 'Italian' },
  es: { id: 'language.selector.option.spanish', defaultMessage: 'Spanish' },
  pl: { id: 'language.selector.option.polish', defaultMessage: 'Polish' },
});

interface ILanguageSelectorProps {
  className?: string;
  label?: React.ReactNode;
}

const LanguageSelector: React.FunctionComponent<ILanguageSelectorProps> = ({
  className,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(changeLanguage(event.target.value));
  };

  return (
    <div className={cn('languageselector', className)}>
      <div className="languageselector__selectcontainer btn btn-medium btn-rounded">
        <select value={locale} onChange={handleChange} className="languageselector__select">
          {Object.keys(translations).map((key, index) => {
            return (
              <option key={index} value={key}>
                {intl.formatMessage((translations as any)[key])}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default LanguageSelector;
